/* global styles */
@import "reset.css";

html {
  height: 100%;
}

body {
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

app-root {
  width: 100%;
  height: 100%;
}

/* Layout */
.container {
  margin: 0 40px;
}

.mt-50 {
  margin-top: 50px;
}

.mb-50 {
  margin-bottom: 50px;
}

.my-50 {
  margin-top: 50px;
  margin-bottom: 50px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-10 {
  margin-top: 10px;
}

/* Design */
.button {
  width: 200px;
  height: 50px;
  align-self: center;
  font-size: 1.5em;
  background-color: rgba(255, 255, 255, 0.75);
  border-style: none;
  box-shadow: 3px 3px 3px 0 rgba(49, 46, 46, 0.5);
  border-radius: 35px;
}

.button-lg {
  width: 300px;
  height: 75px;
  font-size: 2em;
}

.button:hover {
  cursor: pointer;
  transform: translate(-1px, -1px);
  box-shadow: 4px 4px 3px 0 rgba(49, 46, 46, 0.5);
}

.button:active {
  box-shadow: inset -1px -1px 1px rgb(73, 73, 73);
  transform: translate(2px, 2px);
}

.button:focus {
  outline: 0;
  border-radius: 35px;
  box-shadow: 0 0 0 2px black;
}

.outline {
  background-color: rgba(255, 255, 255, 0.25);
  border: rgba(255, 255, 255, 0.75);
  border-width: 4px;
  border-style: solid;
  border-radius: 15px;
  margin-left: auto;
  margin-right: auto;
  max-width: 800px;
  min-width: 400px;
}

/* Backgrounds */
.bg-fill {
  height: 100%;
  overflow-y: auto;
}

.bg-orange {
  background-color: #fbab7e;
  background-image: linear-gradient(62deg, #fbab7e 0%, #f7ce68 100%);
}

.bg-green {
  background-color: #85ffbd;
  background-image: linear-gradient(45deg, #85ffbd 0%, #fffb7d 100%);
}

.bg-blue {
  background-color: #8bc6ec;
  background-image: linear-gradient(135deg, #8bc6ec 0%, #9599e2 100%);
}

.bg-unicorn {
  background-color: #a9c9ff;
  background-image: linear-gradient(180deg, #a9c9ff 0%, #ffbbec 100%);
}

.bg-purple {
  background: #9796f0; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to bottom,
    #fbc7d4,
    #9796f0
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to bottom,
    #fbc7d4,
    #9796f0
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

/* Canvas */
#my-canvas {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 10;
  top: 0px;
  left: 0px;
  background-color: rgba(255, 255, 255, 0);
  pointer-events: none;
}
